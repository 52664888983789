import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { CATALOG_TYPE } from 'shared/constants';
import { useAppSelector, useCatalogsControl, useCatalogSearchParams } from 'shared/hooks';
import { getFatherAvailability } from 'shared/lib';
import { AvailabilityItem, AvailableComponent, AvailableGroup, TableProductInfo } from 'shared/models';
import { AvailabilityService, CatalogService } from 'shared/services';
import { selectCatalogTypes } from 'shared/slices';

type CatalogAvailabilitiesInfo = {
  [catalogId: string]: string; // selectedOrgId
};

type WhiteList = { groupId: string; componentsId: string[] }[];

export const useAvailabilities = () => {
  const { catalogId, type, onChangeManageType } = useCatalogSearchParams();
  const { loadedType, items: catalogProducts, catalogTypeSubtypes } = useCatalogsControl();

  const catalogTypes = useAppSelector(selectCatalogTypes);
  const { flattedOrgs } = useAppSelector((st) => st.orgs);
  const groups = useAppSelector((st) => st.catalogs.groups);
  const catalogGroups = groups[catalogId] ?? [];

  const [isAvailabilitiesLoading, setIsAvailabilitiesLoading] = useState(false);
  const [isProductsLoading, setIsProductsLoading] = useState(false);
  const [isAvailabilitiesSaving, setIsAvailabilitiesSaving] = useState(false);
  const [isOrgHasFatherAvailability, setIsOrgHasFatherAvailability] = useState(false);
  const [isAvailabilityHasChanges, setIsAvailabilityHasChanges] = useState(false);

  const [availabilities, setAvailabilities] = useState<AvailabilityItem[]>([]);
  const [selectedAvailability, setSelectedAvailability] = useState<AvailabilityItem | null>(null);

  const [topOrgTypeGroups, setTopOrgTypeGroups] = useState<AvailableGroup[]>([]);
  const [fatherAvailableGroups, setFatherAvailableGroups] = useState<AvailableGroup[]>([]);

  const [catalogsAvailabilityInfo, setCatalogsAvailabilityInfo] = useState<CatalogAvailabilitiesInfo>({});

  const [currentCatalogId, setCurrentCatalogId] = useState('');
  const [selectedGroupID, setSelectedGroupID] = useState('');
  const [selectedSubtypeID, setSelectedSubtypeID] = useState('');

  const [selectedGroupIDs, setSelectedGroupIDs] = useState<string[]>([]);
  const [selectedProductIDs, setSelectedProductIDs] = useState<string[]>([]);

  const availableCatalogTypes = useMemo(() => {
    return catalogTypes.filter((ct) => ct !== CATALOG_TYPE.DISTRIBUTION_CURVE);
  }, [catalogTypes]);

  const viewGroups = useMemo(() => {
    const groups = fatherAvailableGroups.filter((g) => g.type === type);
    if (!selectedSubtypeID) return groups;

    return groups.filter((g) => g.subtypeId === selectedSubtypeID);
  }, [type, selectedSubtypeID, fatherAvailableGroups]);

  const sortedViewProducts = useMemo(() => {
    if (!selectedGroupID) return [];

    const viewProducts = viewGroups.find((g) => g.id === selectedGroupID)?.availableComponents ?? [];
    return [...viewProducts].sort((a, b) => a.description.localeCompare(b.description));
  }, [selectedGroupID, viewGroups]);

  const selectedViewProductsAmount = sortedViewProducts.filter((p) => selectedProductIDs.includes(p.id)).length;

  const selectedOrgId = catalogsAvailabilityInfo[catalogId];
  const isOrgHaveAvailability = selectedAvailability?.sourceAvailability?.orgId === selectedOrgId;

  const toggleGroup = (id: string) => setSelectedGroupID((prev) => (id === prev ? '' : id));

  const updateCatalogInfo = (orgId: string) => {
    setCatalogsAvailabilityInfo((prev) => ({
      ...prev,
      [catalogId]: orgId,
    }));
  };

  const getAvailableGroups = (type: string, products: TableProductInfo[]) => {
    const filteredGroups = catalogGroups.filter((g) => g.type === type);

    const groups: AvailableGroup[] = filteredGroups.map(({ id, name, type, subtype }) => {
      const filteredProducts = products.filter((p) => p.groupId === id);
      const availableComponents: AvailableComponent[] = filteredProducts.map(
        ({ id, sku = '', description = '' }) => ({ id, sku, description })
      );

      return { id, name, type, availableComponents, subtypeId: subtype?.id ?? '' };
    });
    return groups;
  };

  const handleLoadGroupProducts = async (type: string) => {
    const isNoGroups = !catalogGroups.filter((g) => g.type === type).length;
    if (isNoGroups) {
      setTopOrgTypeGroups([]);
      return [];
    }

    const products = (await CatalogService.getProducts(catalogId, type)) ?? [];
    const groups = getAvailableGroups(type, products);
    setTopOrgTypeGroups(groups);
    return groups;
  };

  const fetchAvailabilities = async () => {
    setIsAvailabilitiesLoading(true);

    let promises: Promise<any>[] = [AvailabilityService.getAvailabilities(catalogId)];
    if (loadedType !== type) {
      const promise = handleLoadGroupProducts(type);
      promises = [...promises, promise];
    } else {
      const groups = getAvailableGroups(type, catalogProducts);
      setTopOrgTypeGroups(groups);
    }

    const [availabilities, topGroups] = await Promise.all(promises);

    if (availabilities) setAvailabilities(availabilities);
    if (topGroups) setTopOrgTypeGroups(topGroups);

    if (selectedOrgId) onSelectOrg(selectedOrgId);

    setIsAvailabilitiesLoading(false);
  };

  useEffect(() => {
    if (catalogId !== currentCatalogId) {
      setSelectedGroupIDs([]);
      setSelectedProductIDs([]);
      setTopOrgTypeGroups([]);
      setFatherAvailableGroups([]);
    }

    setCurrentCatalogId(catalogId);
    fetchAvailabilities();
  }, [catalogId]);

  const onSelectOrg = async (orgId: string) => {
    const openCatalogsAvailabilities = Object.entries(catalogsAvailabilityInfo).filter(([__, value]) => value);
    const isAnotherCatalogAvailabilityOpen = openCatalogsAvailabilities.length > 1;

    // do not load new data if selected org is already open
    if (catalogsAvailabilityInfo[catalogId] === orgId && !isAnotherCatalogAvailabilityOpen) {
      return;
    }

    setIsAvailabilitiesLoading(true);
    updateCatalogInfo(orgId);

    // reset values when open new availability
    setSelectedGroupID('');
    setIsAvailabilityHasChanges(false);

    const orgAvailability = await AvailabilityService.getOrgAvailability(catalogId, orgId);
    setSelectedAvailability(orgAvailability);

    openAvailabilityView(orgId, orgAvailability);
    setIsAvailabilitiesLoading(false);
  };

  const getGroupsByFatherAvailability = async (fatherAvailability: AvailabilityItem | null) => {
    if (fatherAvailability) {
      return fatherAvailability.sourceCatalog.availableGroups;
    }

    const isAnotherLoadedType = type !== topOrgTypeGroups?.[0]?.type || catalogId !== currentCatalogId;

    if (isAnotherLoadedType) {
      setIsProductsLoading(true);
      const groups = await handleLoadGroupProducts(type);

      setIsProductsLoading(false);
      return groups;
    }

    return topOrgTypeGroups;
  };

  const openAvailabilityView = async (orgId: string, orgAvailability: AvailabilityItem | null) => {
    const fatherAvailability = getFatherAvailability(orgId, flattedOrgs, availabilities);
    setIsOrgHasFatherAvailability(!!fatherAvailability);

    const groups = await getGroupsByFatherAvailability(fatherAvailability);
    setFatherAvailableGroups(groups);

    const isOrgHaveAvailability = orgAvailability?.sourceAvailability?.orgId === orgId;

    if (isOrgHaveAvailability) {
      const groups = orgAvailability.sourceCatalog.availableGroups;
      const groupIDs = groups.map(({ id }) => id);
      setSelectedGroupIDs(groupIDs);

      const selectedProducts = groups.flatMap((g) => g.availableComponents.map(({ id }) => id));
      setSelectedProductIDs(selectedProducts);
      return;
    }

    setSelectedGroupIDs([]);
    setSelectedProductIDs([]);
  };

  const handleChangeType = async (newType: string) => {
    if (newType === type) return;
    onChangeManageType(newType);

    const fatherAvailability = getFatherAvailability(selectedOrgId, flattedOrgs, availabilities);
    if (fatherAvailability) return;

    setIsProductsLoading(true);
    const groups = await handleLoadGroupProducts(newType);
    setFatherAvailableGroups(groups);

    setIsProductsLoading(false);
  };

  const onToggleAllProductsSelected = () => {
    setIsAvailabilityHasChanges(true);

    const areAllProductsSelected = selectedViewProductsAmount === sortedViewProducts.length;
    let productIDs: string[];
    let groupIDs: string[] = [...new Set([...selectedGroupIDs])];

    const groupProductIDs = sortedViewProducts.map((p) => p.id);

    if (areAllProductsSelected) {
      productIDs = selectedProductIDs.filter((id) => !groupProductIDs.includes(id));
      groupIDs = groupIDs.filter((id) => id !== selectedGroupID);
    } else {
      productIDs = [...new Set([...selectedProductIDs, ...groupProductIDs])];
      groupIDs = [...new Set([...groupIDs, selectedGroupID])];
    }

    setSelectedProductIDs(productIDs);
    setSelectedGroupIDs(groupIDs);
  };

  const onToggleProduct = (id: string) => {
    setIsAvailabilityHasChanges(true);

    const isAdded = selectedProductIDs.includes(id);
    let productIDs: string[];

    if (isAdded) {
      productIDs = selectedProductIDs.filter((pId) => pId !== id);
      if (!productIDs.length) {
        setSelectedGroupIDs((prev) => prev.filter((id) => id !== selectedGroupID));
      }
    } else {
      productIDs = [...selectedProductIDs, id];
      if (!selectedGroupIDs.includes(selectedGroupID)) {
        setSelectedGroupIDs([...selectedGroupIDs, selectedGroupID]);
      }
    }

    setSelectedProductIDs(productIDs);
  };

  const onSaveAvailability = async () => {
    if (!selectedAvailability) {
      console.error('No availability found');
      return;
    }

    setIsAvailabilitiesSaving(true);

    const whiteListGroupIDs = [...selectedGroupIDs];

    const whiteList = whiteListGroupIDs.map((groupId) => {
      const groupProducts = fatherAvailableGroups.find((g) => g.id === groupId)?.availableComponents ?? [];
      const componentsId = groupProducts.filter(({ id }) => selectedProductIDs.includes(id)).map(({ id }) => id);

      const isSelectedAllGroupItems = groupProducts.length === componentsId.length;
      if (isSelectedAllGroupItems && !isOrgHasFatherAvailability) return { groupId, componentsId: [] };

      return { groupId, componentsId };
    });

    const filteredWhiteList = whiteList.filter((wl) => wl.componentsId.length);

    await handleSaveAvailability(filteredWhiteList);

    setIsAvailabilitiesSaving(false);
    setIsAvailabilityHasChanges(false);
  };

  const handleSaveAvailability = async (whiteList: WhiteList) => {
    if (!isOrgHaveAvailability) {
      await createAvailability(whiteList);
      return;
    }

    if (!whiteList.length) {
      await deleteAvailability();
      onCancelClick();
      return;
    }

    await updateAvailability(whiteList);
  };

  const createAvailability = async (whiteList: WhiteList) => {
    // close availability if no products were selected
    if (!whiteList.length) {
      onCancelClick();
      return;
    }

    const newAvailability = await AvailabilityService.createAvailability(selectedOrgId, catalogId, whiteList);
    if (!newAvailability) return;

    setSelectedAvailability(newAvailability);
    setAvailabilities((prev) => [...prev, newAvailability]);
    toast.success('New availability was created');
  };

  const deleteAvailability = async () => {
    const availabilityId = selectedAvailability?.sourceAvailability.id;
    if (!availabilityId) return;

    const isDeleted = await AvailabilityService.deleteAvailability(availabilityId);
    if (!isDeleted) return;

    const updatedAvailabilities = availabilities.filter((a) => a.sourceAvailability.id !== availabilityId);
    setAvailabilities(updatedAvailabilities);
    toast.success('Availability deleted');
  };

  const updateAvailability = async (whiteList: { groupId: string; componentsId: string[] }[]) => {
    if (!selectedAvailability) return;
    const availabilityId = selectedAvailability.sourceAvailability.id;

    const updatedAvailability = await AvailabilityService.changeAvailability(availabilityId, whiteList);
    if (!updatedAvailability) {
      onCancelClick();
      return;
    }

    const updatedAvailabilities = availabilities.map((a) =>
      a.sourceAvailability.id === availabilityId ? updatedAvailability : a
    );

    const updatedAvailableGroups =
      updatedAvailabilities.find((u) => u.sourceAvailability.id === availabilityId)?.sourceCatalog
        .availableGroups ?? [];

    setSelectedAvailability((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        sourceCatalog: { ...prev.sourceCatalog, availableGroups: updatedAvailableGroups },
      };
    });

    setAvailabilities(updatedAvailabilities);
    toast.success('Changes were successfully saved');
  };

  const onCancelClick = () => {
    // reset to initial page state
    updateCatalogInfo('');
  };

  return {
    type,
    availableCatalogTypes,
    handleChangeType,
    viewGroups,
    viewProducts: sortedViewProducts,
    isAvailabilitiesLoading,
    catalogsAvailabilityInfo,
    onSelectOrg,
    isAvailabilityHasChanges,
    isLoading: isProductsLoading,
    selectedGroupID,
    toggleGroup,
    selectedGroupIDs,
    selectedProductIDs,
    selectedViewProductsAmount,
    isAvailabilitiesSaving,
    onToggleAllProductsSelected,
    onToggleProduct,
    onSaveAvailability,
    onCancelClick,
    isOrgHaveAvailability,
    selectedSubtypeID,
    setSelectedSubtypeID,
    subtypeItems: catalogTypeSubtypes,
  };
};
